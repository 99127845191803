<template>
    <div class="page-ryProbe-afterSale-list">
        <Nav />
        <div class="header">
            <div class="title">返厂维护记录</div>
            <div class="header-right">
                <span class="btn" @click="switchAccount">切换登录</span>
                <span class="btn add-record" v-if="!isShowSureBtn" @click="handleClick">新建记录</span>
            </div>
        </div>
        <div :class="{'content': true, 'max-height': true}">
            <div class="filter-box">
                <el-row :gutter="24">
                    <div class="header-tab">
                        <span :class="{'tab-item': true, 'active': status == 0}" @click="onchangeTab(0)">待确认</span>
                        <span :class="{'tab-item': true, 'active': status == 1}" @click="onchangeTab(1)">待复核</span>
                        <span :class="{'tab-item': true, 'active': status == 2}" @click="onchangeTab(2)">已确认</span>
                        <span :class="{'tab-item': true, 'active': status == 4}" @click="onchangeTab(4)">已完成</span>
                        <span :class="{'tab-item': true, 'active': status == 3}" @click="onchangeTab(3)">已结算</span>

                        <div class="right-btn">
                            <div class="warning-probe-btn" v-if="isShowSureBtn && status == 4" @click="isShowDownloadModal = true">导出数据</div>
                            <div class="warning-probe-btn" v-if="isShowSureBtn && (status == 2 || status == 1)" @click="openUploadModal(1)">出厂核对</div>
                            <div class="warning-probe-btn" v-if="isShowSureBtn && status == 3" @click="openUploadModal(0)">结算归档</div>
                            <!-- <div class="warning-probe-btn clear-warning-btn" v-if="isShowSureBtn" @click="onclickSureBtn">全部确认</div> -->
                            <!-- <div class="warning-probe-btn" @click="jumpWarningPage">异常探头</div> -->
                        </div>
                    </div>
                </el-row>
                <el-row :gutter="24">
                    <el-form label-width="90px" center>
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="pl-10">
                            <el-form-item label="责任方:" label-width="60px">
                                <el-select v-model="responsiblePartyId" @change="loadCheckList(1)" placeholder="请选择">
                                    <el-option v-for="item in responsiblePartyList" :key="item.val" :label="item.text" :value="item.val"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4" class="pl-10">
                            <el-form-item label="探头批次:" label-width="72px">
                                <el-select v-model="probeBatch" @change="loadCheckList(1)" placeholder="请选择">
                                <el-option v-for="item in probeBatchList" :key="item" :label="item" :value="item"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="pl-10" v-if="!status">
                            <el-form-item label="返厂原因:" label-width="72px">
                                <el-select v-model="warningType" @change="loadCheckList(1)" placeholder="请选择">
                                <el-option v-for="item in tagList" :key="item.id" :label="item.tagName" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="pl-10" v-if="status">
                            <el-form-item label="检查结果:" label-width="72px">
                                <el-select v-model="resultTagId" @change="loadCheckList(1)" placeholder="请选择">
                                <el-option v-for="item in resultTagList" :key="item.id" :label="item.tagName" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="10" :sm="10" :md="9" :lg="9" :xl="9">
                            <el-form-item label="时间:" label-width="72px">
                                <el-col :span="11">
                                    <el-form-item prop="startTime">
                                        <el-date-picker type="date" @change="loadCheckList" placeholder="开始时间" v-model="startTime" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="1">-</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="endTime">
                                        <el-date-picker type="date" @change="loadCheckList" placeholder="结束时间" v-model="endTime" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="6" :sm="6" :md="6" :lg="7" :xl="7" class="pl-10">
                            <el-form-item label="探头ID:" prop="number" label-width="72px">
                                <el-input placeholder="请输入探头ID" @input="inputChange('search')" v-model="number">
                                    <template slot="append"><span :loading="loading" @click="search">搜索</span></template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="5" :sm="5" :md="6" :lg="7" :xl="7" class="pl-10 pos-filter-item" v-if="status == 4">
                            <el-form-item label="" prop="number" label-width="0">
                                <el-checkbox v-model="scrap" @change="loadCheckList">报废设备</el-checkbox>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
            </div>
            <div :class="{'table-body': true}">
                <div class="table-box" v-show="!status">
                    <el-table
                        :data="list"
                        stripe
                        style="width: 100%">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item label="备注：">
                                    <span>{{ '   ' + props.row.remark }}</span>
                                </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="探头 ID"
                        prop="deviceId">
                        </el-table-column>

                        <el-table-column
                        label="返厂原因">
                            <template slot-scope="scope">
                                <span v-if="scope.row.tagNames && scope.row.tagNames.length > 1">{{scope.row.tagNames.join('、')}}</span>
                                <span v-else>{{scope.row.tagNames ? scope.row.tagNames[0] : '-'}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                        label="返厂备注">
                            <template slot-scope="scope">
                                <div v-if="!scope.row.status">{{scope.row.remark}}</div>
                                <div v-else>
                                    <span v-if="scope.row.tagNames && scope.row.tagNames.length > 1">{{scope.row.tagNames.join('、')}}</span>
                                    <span v-else>{{scope.row.tagNames ? scope.row.tagNames[0] : '-'}}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                        :label="timeText"
                        prop="time">
                        </el-table-column>

                        <el-table-column
                        label="操作">
                            <template slot-scope="scope">
                                <el-button @click="viewInfo(scope.row)" type="text" size="mini">查看</el-button>
                                <el-button @click="handleClick(scope.row)" type="text" v-if="!status && !isShowSureBtn" size="mini">开始质检</el-button>
                                <el-button @click="handleClick(scope.row, 2)" v-if="isShowSureBtn && status == 1" type="danger" size="mini">修改 </el-button>
                                <el-button @click="handleClick(scope.row, 1)" v-if="isShowSureBtn && status == 1" type="success" size="mini">同意 </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="table-box" v-show="status == 1 || status == 2 || status == 4">
                    <el-table
                        :data="list"
                        stripe
                        style="width: 100%">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item label="备注：">
                                    <span>{{ '   ' + props.row.remark }}</span>
                                </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="探头 ID"
                        width="100"
                        prop="deviceId">
                        </el-table-column>

                        <el-table-column
                        label="质检结果">
                            <template slot-scope="scope">
                                <div v-if="!scope.row.status">{{scope.row.remark}}</div>
                                <div v-else>
                                    <span v-if="scope.row.tagNames && scope.row.tagNames.length > 1">{{scope.row.tagNames.join('、')}}</span>
                                    <span v-else>{{scope.row.tagNames ? scope.row.tagNames[0] : '-'}}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                        width="100"
                        label="责任方"
                        prop="responsiblePartyText">
                        </el-table-column>

                        <el-table-column
                        label="处理方式"
                        prop="handlingMethodFinalText">
                        </el-table-column>

                        <el-table-column
                        :label="timeText"
                        prop="time">
                        </el-table-column>

                        <el-table-column
                        label="操作">
                            <template slot-scope="scope">
                                <el-button @click="viewInfo(scope.row)" type="text" size="mini">查看</el-button>
                                <el-button @click="handleClick(scope.row)" type="text" v-if="!status && !isShowSureBtn" size="mini">开始质检</el-button>
                                <el-button @click="handleClick(scope.row, 2)" v-if="isShowSureBtn && status == 1" type="danger" size="mini">修改 </el-button>
                                <el-button @click="handleClick(scope.row, 1)" v-if="isShowSureBtn && status == 1" type="success" size="mini">同意 </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="table-box" v-show="status == 3">
                    <el-table
                        :data="list"
                        stripe
                        style="width: 100%">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item label="备注：">
                                    <span>{{ '   ' + props.row.remark }}</span>
                                </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="探头 ID"
                        width="100"
                        prop="deviceId">
                        </el-table-column>

                        <el-table-column
                        width="100"
                        label="责任方"
                        prop="responsiblePartyText">
                        </el-table-column>

                        <el-table-column
                        label="处理方式"
                        prop="handlingMethodFinalText">
                        </el-table-column>

                        <el-table-column
                        :label="timeText"
                        prop="time">
                        </el-table-column>

                        <el-table-column
                        label="操作">
                            <template slot-scope="scope">
                                <el-button @click="viewInfo(scope.row)" type="text" size="mini">查看</el-button>
                                <el-button @click="handleClick(scope.row)" type="text" v-if="!status && !isShowSureBtn" size="mini">开始质检</el-button>
                                <el-button @click="handleClick(scope.row, 2)" v-if="isShowSureBtn && status == 1" type="danger" size="mini">修改 </el-button>
                                <el-button @click="handleClick(scope.row, 1)" v-if="isShowSureBtn && status == 1" type="success" size="mini">同意 </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination
                    @size-change="onChangePageSize"
                    @current-change="onChangePageNo"
                    :page-no="pageOptions.pageNo"
                    :current-page="pageOptions.pageNo"
                    :page-sizes="pageOptions.pageSizes"
                    :page-size="pageOptions.pageSize"
                    center
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageOptions.total">
                </el-pagination>
            </div>
        </div>
        <!-- <div class="footer">
            <img :src="ossHost + 'img/logo.png'" alt="">
        </div> -->

        <el-dialog title="请选择需要确认的订单责任方"
            :visible.sync="isShowSureModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="onCancel"
            center>
            <el-form :model="form">
                <div :class="{'select-model-item': true, 'active': sureResponsibleModel == 5}" @click="onclickSelect(5)">览宋</div>
                <div :class="{'select-model-item': true, 'active': sureResponsibleModel == 4}" @click="onclickSelect(4)">英沃</div>
                <div class="modal-tip">*点击“确定”后，对应责任方的维护订单将变为“已确认”状态</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowSureModal = false" :loading="loading">取 消</el-button>
                <el-button type="primary" @click="onConfirmSelect" :disabled="!sureResponsibleModel" :loading="loading">确认</el-button>
            </div>
        </el-dialog>

        <el-dialog title="请选择需要导出的时间"
            :visible.sync="isShowDownloadModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="onCancel"
            center>
            <el-form style="width: 70%;margin: 20px auto;min-height: 50px">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-col :span="11">
                        <el-form-item prop="startTime">
                            <el-date-picker type="date" @change="loadCheckList" placeholder="开始时间" v-model="startDownloadTime" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="line line-two" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-form-item prop="endTime">
                            <el-date-picker type="date" @change="loadCheckList" placeholder="结束时间" v-model="endDownloadTime" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowDownloadModal = false" :loading="loading">取 消</el-button>
                <el-button type="primary" @click="onclickDownloadBtn" :disabled="!(startDownloadTime && endDownloadTime)" :loading="loading">确认导出</el-button>
            </div>
        </el-dialog>

       <el-dialog
          title="上传表格"
          :visible.sync="isShowUploadModal"
          center
          width="50%"
          @close="modalClose"
        >
          <div v-show="modalStep == 1" class="dialog-body">
            <el-upload
              ref="upload"
              class="upload-demo"
              :on-change="onChange"
              :before-remove="handelRemove"
              action=""
              :limit="1"
              accept=".xls,.xlsx"
              :auto-upload="false"
              list-type="file"
              :file-list="fileList"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text"><em>点击上传</em></div>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
            </el-upload>
          </div>
          <div v-if="modalStep == 2" class="dialog-body">
            <div v-if="settlementData.successCount == settlementData.total" class="success-res">
              <div class="el-icon-success" />
              <div class="success-title">归档成功</div>
              <div class="success-tip">本次成功归档{{ settlementData.successCount }}个返厂订单</div>
            </div>
            <div v-else class="fail-res">
              <div class="fail-tip">本次归档总共{{ settlementData.total }}条订单。成功{{ settlementData.successCount + settlementData.succeedCount }}单(含{{settlementData.succeedCount}}条之前已结算)，<span>失败{{ settlementData.failureSize }}条</span></div>
              <div class="fail-table">
                <div class="table-header">
                  <div class="left">设备编码</div>
                  <div class="right">失败原因</div>
                </div>
                <div v-for="item in settlementData.failureReason" :key="item.first" class="list-item">
                  <div class="left">{{ item.first }}</div>
                  <div class="right">{{ item.second }}</div>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button v-if="modalStep == 1" @click="isShowUploadModal = false">取 消</el-button>
            <el-button v-if="modalStep == 2" @click="isShowUploadModal = false">关 闭</el-button>
            <el-button v-if="modalStep == 1" type="primary" :loading="loading" :disabled="!fileList.length" @click="onSure">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="出厂核对"
            :visible.sync="isShowCheckProbeModal"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="80%"
            @close="onCancel"
            center>
            <el-tabs  class="el-tab-checkStatus" v-model="checkStatus"  @tab-click="onCheckProbeFilteTab" >
                <el-tab-pane  :name="'1'">
                  <template #label>
                      <el-badge :value="tabsSize[1]">
                        <span>待复核</span>
                      </el-badge>
                  </template>
                </el-tab-pane>
                <el-tab-pane  :name="'2'">
                  <template #label>
                    <el-badge :value="tabsSize[2]">
                      <span>已确认</span>
                    </el-badge>
                  </template>
                </el-tab-pane>
                <el-tab-pane  :name="'-1'">
                  <template #label>
                    <el-badge :value="tabsSize[0]">
                      <span>异常订单</span>
                    </el-badge>
                  </template>
                </el-tab-pane>
            </el-tabs>
            <el-form v-if="checkList.length">
                <div class="check-list-box" v-if="checkList[0].status == 1 || checkList[0].status == 2">
                    <div class="check-status-item" v-for="(item, index) in checkList" :key="item.deviceId">
                        <div class="item-header">
                            <span v-text="item.deviceId"></span>
                            <el-button type="success" size="mini" class="pull-right" @click="onClickUpdateOrderStatus(1, item, index)">允许出厂</el-button>
                            <el-button type="danger" size="mini" class="pull-right" @click="onClickUpdateOrderStatus(0, item, index)">报废</el-button>
                        </div>
                        <div class="item-desc">
                            <span>责任方：{{item.responsibleParty == 5 ? '览宋' : '英沃'}}</span>
                            <span>处理方式：{{item.handlingMethod | filterHandlingMethod}}</span>
                            <span v-if="item.tagNames && item.tagNames.length">质检结果：{{item.tagNames.join('、')}}</span>
                            <span v-else>质检结果：-</span>
                            <span>故障描述：{{item.remark || '暂无'}}</span>
                        </div>
                        <div class="item-info">
                            <div class="title">返厂信息 <el-button type="primary" v-if="!item.cliabData && item.hasAfterSale" :loading="item.loading" size="mini" class="pull-right" @click="onclickLoadProbeInfo(item, index)">展开</el-button></div>
                            <div class="cliab-box" v-if="item.cliabData && item.hasAfterSale">
                                <div class="device-info">
                                    <span>返厂原因：{{item.cliabData ? item.cliabData.preTagName : '-'}}</span>
                                    <span>故障描述：{{item.cliabData ? item.cliabData.preRemark : '-'}}</span>
                                </div>
                                <div class="device-info">
                                    <span>故障曲线：</span>
                                </div>
                                <div class="item-imgs" v-if="item.cliabData && item.cliabData.images && item.cliabData.images.length">
                                    <a :href="img" target="_blank" :alt="img" v-for="img in item.cliabData.images" :key="img"><img :src="img"></a>
                                </div>
                                <div class="item-imgs" v-else>暂无</div>
                            </div>
                            <div class="empty-check" v-if="!item.hasAfterSale">暂无返厂信息</div>
                        </div>

                        <div class="item-info">
                            <div class="title">详细信息 <el-button type="primary" v-if="!item.cliabData" :loading="item.loading" size="mini" class="pull-right" @click="onclickLoadProbeInfo(item, index)">展开</el-button></div>
                            <div class="cliab-box" v-if="item.cliabData">
                                <div class="device-info">
                                    <span>设备编号：{{item.uniqueId || '-'}}</span>
                                    <span>开始使用：{{item.cliabData.startUserAt | getYMD}}</span>
                                    <span v-if="item.createParty == 5">开通维护：{{item.createAt | getYMD}}</span>
                                </div>
                                <div class="init-check" v-if="item.cliabData.leaveFacQc">
                                    <span>出厂日期：{{item.cliabData.leaveFacQc.updateAt | getYMD}}</span>
                                    <span>出厂AD：{{item.cliabData.leaveFacQc.initSaturationOxy}}</span>
                                    <span>标定温度：{{item.cliabData.leaveFacQc.initSaturationOxyTemp}}</span>
                                    <el-button type="text" @click="viewCalibInfo(item.cliabData.leaveFacQc)">查看</el-button>
                                </div>
                                <div class="empty-check" v-if="!item.cliabData.leaveFacQc">暂无出厂记录</div>
                                <div class="last-check" v-if="item.cliabData.latestFacQc">
                                    <span>最新质检：{{item.cliabData.latestFacQc.updateAt | getYMD}}</span>
                                    <span>出厂AD：{{item.cliabData.latestFacQc.initSaturationOxy}}</span>
                                    <span>标定温度：{{item.cliabData.latestFacQc.initSaturationOxyTemp}}</span>
                                    <el-button type="text" @click="viewCalibInfo(item.cliabData.latestFacQc)">查看</el-button>
                                </div>
                                <div class="empty-check" v-if="!item.cliabData.latestFacQc">暂无返厂质检记录</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="check-list-box" v-else>
                    <div class="check-status-empty" v-for="item in checkList" :key="item.deviceId">
                        <span v-text="item.deviceId"></span>
                        <span v-if="!(Number(item.status + '') > -1 && Number(item.status + '') < 3)">未在“待确认”，“待复核”和“已确认”中找到该探头的订单</span>
                        <span v-else>待英沃确认</span>
                    </div>
                </div>
            </el-form>
            <div v-else>暂无记录</div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowCheckProbeModal = false" :loading="loading">关闭</el-button>
                <el-button v-if="checkStatus == 2" type="success"  @click="isShowOutFactoryBatch = true" :loading="loading">批量出厂</el-button>
            </div>
        </el-dialog>

        <el-dialog title="出厂质检项"
            :visible.sync="isShowCalibModal"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            center>
            <div class="info-content">
                <div class="info-content-title">出厂质检项：</div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item, index) in failArr" :key="index">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item) in passArr" :key="item.data">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isShowCalibModal = false">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="请确认责任方和处理方式"
            :visible.sync="isShowLatestReason"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            center>
            <el-form>
                <el-form-item label="责任方">
                    <el-select v-model="latestData.responsibleParty" @change="onchangeLastRp" placeholder="请选择">
                        <el-option v-for="item in responsiblePartyList" :key="item.val" :label="item.text" v-show="item.val" :value="item.val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="处理方法">
                    <el-input style="width: auto" :inline="true" v-model="latestData.handlingMethod" :disabled="true"></el-input>
                </el-form-item>
                <!-- <el-form-item label="故障原因">
                    <el-select v-model="latestData.iotTagId" placeholder="请选择">
                        <el-option v-for="item in tagList" :key="item.id" :label="item.tagName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item> -->
                <div class="modal-tip" style="color: #F1952F" v-if="!latestData.updateOrderType">*确认报废后订单状态将改为“已完成”,不得再修改</div>
                <div class="modal-tip" style="color: #F1952F" v-if="latestData.updateOrderType">*允许出厂后订单状态将改为“已完成”,不得再修改</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isShowLatestReason = false">取消</el-button>
                <el-button :type="latestData.handlingMethod == '报废' ? 'danger' : 'primary'"
                    :loading="subLatestReasonLoading"
                    @click="onClickLatestCheck">{{latestData.handlingMethod == '报废' ? '报废' : '确定'}}</el-button>
            </div>
        </el-dialog>

        <el-dialog title="是否确认?"
                   :visible.sync="isShowOutFactoryBatch"
                   :close-on-click-modal="true"
                   :close-on-press-escape="true"
                   center>
            <div class="info-content-title">已确认状态下剩余{{tabsSize[2]}}条订单,是否全部允许出厂?</div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="isShowOutFactoryBatch = false, outFactoryBatchLoading =false">关闭</el-button>
            <el-button type="primary"
                       :loading="outFactoryBatchLoading"
                       @click="onClickOutFactoryBatch">确定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="搜索结果"
                   :visible.sync="isShowSearchModal"
                   :close-on-click-modal="true"
                   :close-on-press-escape="true"
                   center>
            <div class="info-content-title">
                <el-table
                :data="searchProbeList"
                style="width: 100%">
                    <el-table-column
                        label="订单状态"
                        width="180">
                        <template slot-scope="scope">
                            <span>{{ scope.row.status | filterProbeOrderStatus }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="deviceId"
                        label="探头ID"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        label="创建时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.createAt | getYMD }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" size="small" @click="viewInfo(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isShowSearchModal = false">关闭</el-button>
            </div>
        </el-dialog>

        <UpdateProbeCheckModal ref="updateProbeCheckModal" @change="loadCheckList" />
    </div>
</template>

<script>
import { Dialog, Toast, Icon } from "vant";
import { getYMD } from '@/utils/time'
import { filterHandlingMethod, filterProbeOrderStatus } from '@/utils/str'
import Nav from '@/components/nav'
import UpdateProbeCheckModal from '@/modal/ryProbe/updateProbeCheckModal';
export default {
    name: "RyProbeAfterSaleList",
    components: {
        Nav,
        Icon,
        UpdateProbeCheckModal
    },
    filters: {
        getYMD,
        filterProbeOrderStatus,
        filterHandlingMethod
    },
    data() {
        const { ossHost } = this.$config.base;
        const responsiblePartyList = [
            { text: '全部', val: '' },
            { text: '览宋', val: 5 },
            { text: '英沃', val: 4 }
        ]
        const pageNo = localStorage.getItem('ryProbeAfterSaleListPageNo') || 1
        return {
            ossHost,
            warningType: '',
            startTime: '',
            endTime: '',
            number: '',
            list: [],
            tagList: [],
            preTagList: [],
            resultTagList: [],
            isShowModal: false,
            formLabelWidth: '80px',
            editItem: {},
            isShowUploadModal: false,
            modalStep: 1,
            pageOptions: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                total: 0,
                pageNo: Number(pageNo)
            },
            form: {
                probeId: '',
                tagId: 1,
                remark: '',
                responsibleParty: ''
            },
            isConfirmPass: false,
            singleItem: {},
            loading: false,
            probeBatchList: [],
            probeBatch: '全部',
            status: 0,
            responsiblePartyList,
            responsiblePartyId: '',
            resultTagId: '',
            isShowSureModal: false,
            sureResponsibleModel: '',
            isShowDownloadModal: false,
            startDownloadTime: '',
            endDownloadTime: '',
            createResponsiblePartyObj: {},
            settlementData: {},
            fileList: [],
            responsiblePartyFinalText: '',
            checkList: [],
            // 1:待复核，2:已确认，-1:异常订单
            checkStatus: '1',
            // 是否报废
            scrap: false,
            // 依次记录数量 index从0开始
            tabsSize:[0,0,0],
            isShowCheckProbeModal: false,
            isShowCalibModal: false,
            isShowOutFactoryBatch: false,
            passArr: [],
            failArr: [],
            // 最终确认报废或者出厂
            latestData: {},
            isShowLatestReason: false,
            subLatestReasonLoading: false,
            outFactoryBatchLoading: false,
            searchProbeList: [],
            isShowSearchModal: false,
            timeText: '',
            name: ''
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')
        const warningStatusTabIndex = localStorage.getItem('warningStatusTabIndex')
        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.name = name;
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            (fType != 'yingwo' || !name) && vm.$router.replace({
                path: "/ryProbe/afterSale/login"
            });
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        vm.pageNo = 1;
        vm.pageSize = 10;
        vm.status = Number(warningStatusTabIndex);
        vm.loadTag();
        vm.loadTag(true);
        vm.loadprobeBatchList()
    },
    computed: {
        isShowSureBtn() {
            const vm = this;
            const { USER_NAME } = vm.$config.keys;
            const name = vm.$localStorage.getItem(USER_NAME);
            return name == 'Yudada'
        },
    },
    methods: {
        loadTag(isResult) {
            const vm = this;
            const params = {
                type: 10
            }
            !isResult ? (params.type = 6) : (params.handlingType = 1)
            vm.$http({
                type: "get",
                // url: `${vm.$config.base.PROBE_BASE_URL}${isResult ? 'fc/careInfo/tags' : 'returnFac/tags'}`,
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/tags`,
                params
            }).then(res => {
                if (isResult) {
                    vm.resultTagList = [{
                        id: '',
                        tagName: "全部"
                    }].concat(res.data)
                } else {
                    vm.tagList = [{
                        id: '',
                        tagName: "全部"
                    }].concat(res.data)
                    vm.preTagList = res.data
                }
                vm.loadCheckList();
            });
        },
        loadprobeBatchList() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/probeBatch`
            }).then(res => {
                const plist = []
                res.data.map(item => {
                    plist.push(item.probeBatch)
                })
                vm.probeBatchList = ['全部'].concat(plist)
            });
        },

        handelRemove() {
            this.fileList = []
        },

        // 确认报废或者翻新出厂
        onClickLatestCheck() {
            const vm = this
            const params = Object.assign({}, vm.latestData)
            params.handlingMethod = params.handlingMethod == '报废' ? 2 : 3;
            vm.subLatestReasonLoading = true
            vm.$http({
                type: 'post',
                url: `${vm.$config.base.PROBE_BASE_URL}probeFacCare/${params.id}/manualAudit`,
                data: params
            }).then(res => {
               if (res.data) {
                    let dIndex = -1;
                    vm.checkProbeMetaData.map((cItem, index) => {
                        cItem.id == vm.checkList[vm.selectIndex].id && (dIndex = index)
                    })
                    vm.subLatestReasonLoading = false
                    vm.isShowLatestReason = false
                    vm.checkList.splice(vm.selectIndex, 1);
                    dIndex > -1 && vm.checkProbeMetaData.splice(dIndex, 1)
                    vm.filterCheckListArr()
                    Toast.success({
                        message: '操作成功'
                    })
               }
            }).catch(err => {
                console.log(err)
                document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
                vm.subLatestReasonLoading = false
            });
        },

      /*
        批量允许出厂
        选中status = 2 状态下的记录出厂
       */
      onClickOutFactoryBatch() {
        const vm = this
        const params = {
          password: vm.$config.base.password,
          operatorName: vm.name,
          factoryCareInfoIds:[]
        }
        params.factoryCareInfoIds = vm.checkList.filter(item => item.status = 2).map(item => item.id);
        vm.outFactoryBatchLoading = true
        vm.$http({
                   type: 'post',
                   url: `${vm.$config.base.PROBE_BASE_URL}probeFacCare/manualAudit`,
                   data: params
                 }).then(res => {
          if (res.code == 1) {
            // 清除所有的确认出厂的记录
            const arr =  vm.checkProbeMetaData.filter(item => 2 == item.status)
            arr.forEach(item => {
              var dCheckListIndex = -1;
              vm.checkProbeMetaData.forEach(metaItem =>{
                if (metaItem.id == item.id) {
                  metaItem.id = null
                  metaItem.status = 4
                }
              })
              vm.checkList.forEach((items,index) =>{
                items.id == item.id && (dCheckListIndex = index)
              })
              dCheckListIndex > -1 && vm.checkList.splice(dCheckListIndex, 1)
            })
            // 关闭弹窗
            vm.outFactoryBatchLoading = false
            vm.isShowOutFactoryBatch = false
            vm.filterCheckListArr()
            console.log("操作成功！")
            document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
          }
        }).catch(err => {
          vm.outFactoryBatchLoading = false
          vm.isShowOutFactoryBatch = false
          console.log(err)
          document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
        });
      },

        // 点击报废或者出厂按钮,0报废,1出厂
        onClickUpdateOrderStatus(type, item, index) {
            const vm = this
            vm.selectIndex = index
            vm.latestData = {
                handlingMethod: type == 0 ? (item.responsibleParty == 4 ? '补新' : '报废') : '维护或翻新',
                // iotTagId: item.tagId,
                manualAudit: type == 1 ? 1: 2,
                responsibleParty: item.responsibleParty,
                id: item.id,
                password: vm.$config.base.password,
                operator: vm.name,
                updateOrderType: type
            }
            vm.isShowLatestReason = true
        },

        // 报废或者批准出厂选择责任方
        onchangeLastRp() {
            const vm = this
            if (vm.latestData.updateOrderType == 0) {
                vm.latestData.handlingMethod = vm.latestData.responsibleParty == 4 ? '补新' : '报废'
            }
        },

        // 查看质检治具详细数据
        viewCalibInfo(data) {
            const vm = this
            if (data) {
                vm.passArr = data.content.filter(item => item.state);
                vm.failArr = data.content.filter(item => !item.state);
            }
            vm.isShowCalibModal = true
        },

        // 获取探头详情
        onclickLoadProbeInfo(item, index) {
            const vm = this
            vm.checkList[index].loading = true;
            vm.$http({
                type: 'get',
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${item.id}`
            }).then(res => {
               if (res.data) {
                    vm.checkList[index].loading = false;
                    vm.checkList[index].cliabData = res.data;
                    vm.checkList = [].concat(vm.checkList)
               } else {
                   document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
               }
            }).catch(err => {
                console.log(err)
                document.getElementsByClassName('van-dialog')[0].style.zIndex = 3333
                vm.checkList[index].loading = false;
            });
        },

        // 切换状态
        onchangeTab(index) {
            const vm = this
            if (index == vm.status) {
                return
            }
            vm.number = ''
            vm.status = index;
            localStorage.setItem('warningStatusTabIndex', index)
            vm.pageOptions.pageNo = 1;
            vm.list = []
            vm.loadCheckList()
        },

        onCheckProbeFilteTab() {
            const vm = this
            vm.checkList = vm.filterCheckListArr()
        },

        // type: 0结算归档，1出厂核对
        openUploadModal(type) {
            const vm = this
            vm.uploadType = type
            vm.isShowUploadModal = true
        },

        onChange(file, files) {
            this.fileList = files
        },

        onConfirmSelect() {
            const vm = this
            vm.isShowSureModal = false
            Dialog.confirm({
                title: "提示",
                message: `是否确认所有责任方为${vm.sureResponsibleModel == 5 ? '览宋' : '英沃'}的维护单`,
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                vm.ttid = Toast.loading({
                    forbidClick: true,
                    overlay: true,
                    duration: 0
                });
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/confirm`,
                    data: {
                        responsibleParty: vm.sureResponsibleModel,
                        password: vm.$config.base.password
                    }
                }).then(() => {
                    vm.ttid && vm.ttid.clear()
                    Toast.success({
                        message: '已全部确认'
                    })
                    vm.loadCheckList()
                }).catch(err => {
                    vm.ttid && vm.ttid.clear()
                    vm.loading = false;
                    console.log(err)
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        onclickSelect(model) {
            this.sureResponsibleModel = model;
        },

        modalClose() {
            const vm = this
            vm.modalStep = 1;
            vm.settlementData = {}
            vm.$refs.upload.clearFiles()
        },

        onSure() {
            const vm = this
            const fd = new FormData()
            fd.append('file', vm.fileList[0].raw)
            vm.loading = true

            if (vm.uploadType == 1) {
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}probe/outFactoryCheck?password=${vm.$config.base.password}`,
                    data: fd
                }).then((res) => {
                    const { data } = res
                    vm.loading = false;
                    vm.checkProbeMetaData = data || []
                    vm.checkList = vm.filterCheckListArr();
                    vm.isShowUploadModal = false
                    vm.isShowCheckProbeModal = true
                    vm.$refs.upload.clearFiles()
                }).catch(err => {
                    vm.loading = false
                    vm.$refs.upload.clearFiles()
                    console.log(err)
                });
                return
            }

            vm.$http({
                type: "POST",
                url: `${vm.$config.base.PROBE_BASE_URL}probeFacCare/settlement?password=${vm.$config.base.password}`,
                data: fd
            }).then((res) => {
                const { data } = res
                vm.loading = false;
                vm.settlementData = data || {}
                vm.modalStep = 2;
                vm.$refs.upload.clearFiles()
            }).catch(err => {
                vm.loading = false
                vm.$refs.upload.clearFiles()
                console.log(err)
            });
        },

        filterCheckListArr() {
            const vm = this
            // 过滤出符合条件的信息
            const arr = vm.checkProbeMetaData.filter(item => Number(vm.checkStatus) > -1
                                                             ? (item.status == vm.checkStatus)
                                                             : (!item.id || item.status > 2 || item.status == 0 ))
            vm.refreshTabSize()
            return arr || [];
        },

        refreshTabSize() {
          const vm = this;
          vm.$set(vm.tabsSize, 0, vm.checkProbeMetaData.filter(item => (!item.id || item.status > 2 || item.status == 0 )).length)
          vm.$set(vm.tabsSize, 1,  vm.checkProbeMetaData.filter(item => item.status == 1).length)
          vm.$set(vm.tabsSize, 2, vm.checkProbeMetaData.filter(item => item.status == 2).length)
        },

        onclickDownloadBtn() {
            const vm = this
            if (new Date(vm.endDownloadTime).getTime() < new Date(vm.startDownloadTime).getTime()) {
                Toast.fail('结束时间必须大于开始时间~')
                return
            }
            vm.loading = true
            vm.$http({
                type: "GET",
                url: `${vm.$config.base.PROBE_BASE_URL}factory/careInfo/extra`,
                params: {
                    startAt: parseInt(new Date(vm.startDownloadTime).getTime() / 1000),
                    endAt: parseInt(new Date(vm.endDownloadTime).getTime() / 1000) + 3600 * 24 - 1,
                    statusCode: vm.status
                },
                except: true,
                responseType: 'arraybuffer'
            }).then((res) => {
                const blob = new Blob([res], {type: 'text/plain'});
                let filename = getYMD(new Date(vm.startDownloadTime).getTime(), true) + '__' + getYMD(new Date(vm.endDownloadTime).getTime(), true)
                let url = URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', `英沃结算单(${filename.split('/').join('-')}).xls`)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href)
                document.body.removeChild(link)
                setTimeout(() => {
                    vm.loading = false
                    vm.isShowDownloadModal = false
                }, 1500);
            }).catch(err => {
                vm.loading = false
                console.log(err)
            });
        },

        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                const { USER_NAME } = vm.$config.keys;
                vm.$localStorage.removeItem(USER_NAME);
                vm.$router.replace({
                    path: "/ryProbe/afterSale/login"
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        search() {
            const vm = this;
            let err = ''
            if (vm.number.length < 5) {
                err = '请输入正确的ID'
            }

            if (err) {
                Toast.fail({
                    message: err
                })
                return
            }

            vm.loading = true;
            vm.ttid = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });Toast.loading
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/search`,
                params: {
                    probeId: vm.number
                },
                except: true
            }).then(res => {
                vm.loading = false;
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.ttid && vm.ttid.clear();
                vm.searchProbeList = res.data || []
                vm.isShowSearchModal = true
            }).catch(() => {
                vm.ttid && vm.ttid.clear();
            })
        },
        onChangePageNo(pageNo) {
            this.pageOptions.pageNo = pageNo
            this.loadCheckList()
        },
        onChangePageSize(pageSize) {
            this.pageOptions.pageSize = pageSize
            this.loadCheckList()
        },
        loadCheckList() {
            const vm = this;
            const params = {
                pageNo: vm.pageOptions.pageNo,
                pageSize: vm.pageOptions.pageSize,
                status: vm.status
            }

            if (vm.status == 4 && vm.scrap) {
                params.scrap = true
            }

            // 待检查的筛选状态
            if (!vm.status) {
                vm.warningType && (params.tagId = vm.warningType)
            } else {
                Number(vm.probeBatch) > 0 && (params.probeBatch = vm.probeBatch)
                vm.resultTagId && (params.tagId = vm.resultTagId)
                vm.responsiblePartyId && (params.responsibleParty = vm.responsiblePartyId)
            }

            if (vm.startTime && vm.endTime) {
                params.startAt = new Date(vm.startTime).getTime() / 1000
                params.endAt = (new Date(vm.endTime).getTime() / 1000) + 3600 * 24 - 1
            }

            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/list`,
                params,
                except: true
            }).then(res => {
                vm.loading = false;
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                res.data.data.map(item => {
                    let tagName = item.preTagName
                    if (vm.status > 2) {
                        tagName = item.tagNameFinal
                    }
                    let time = item.createAt
                    vm.status == 1 && (time = item.checkAt)
                    vm.status == 2 && (time = item.confirmAt)
                    vm.status == 3 && (time = item.settlementAt)
                    vm.status == 4 && (time = item.finishAt)
                    // vm.tagList.filter(t => t.id == item.tagId)[0] && (item.tagName = vm.tagList.filter(t => t.id == item.tagId)[0].tagName)
                    item.tagName = tagName
                    item.time = getYMD(time)
                    item.warningTagName = tagName
                    item.responsiblePartyText = '英沃'
                    item.responsibleParty == 5 && (item.responsiblePartyText = '览宋')
                    item.handlingMethodFinalText = filterHandlingMethod(item.handlingMethod)
                    localStorage.setItem('ryProbeAfterSaleListPageNo', vm.pageOptions.pageNo)
                })
                vm.list = [].concat(res.data.data);
                vm.pageOptions.total = res.data.total
                if (vm.number && !res.data.data.length) {
                    vm.isShowModal = true;
                    vm.form = {
                        probeId: vm.number || '',
                        tagId: '',
                        remark: ''
                    }
                }

                vm.status == 1 && (vm.timeText = '质检时间')
                vm.status == 2 && (vm.timeText = '确认时间')
                vm.status == 3 && (vm.timeText = '结算时间')
                vm.status > 3 && (vm.timeText = '完成时间')
                !vm.status && (vm.timeText = '创建时间')
            }).catch(() => {
                vm.loading = false;
            });
        },
        // type: 2修改，1同意
        handleClick(row, type) {
            this.$refs.updateProbeCheckModal.initData(row, type)
        },

        // 全部确认
        onclickSureBtn() {
            this.sureResponsibleModel = ''
            this.isShowSureModal = true
        },

        // 查看维护详情
        viewInfo(row) {
            const url = this.$router.resolve({
                path: `/ryProbe/afterSale/info/${row.id}`
            });
            window.open(url.href, '_blank');
        },

        jumpWarningPage() {
            this.$router.push({
                path: '/ryProbe/afterSale/warningList'
            })
        },

        onCancel() {
            this.editItem = {};
            this.singleItem = {};
            this.singleItem = {};
            this.isShowModal = false;
            this.createResponsiblePartyObj = {}
        },
        onConfirm() {
            const vm = this
            const { createResponsiblePartyObj, form } = vm
            if (createResponsiblePartyObj.responsibleParty && createResponsiblePartyObj.responsibleParty != form.responsibleParty && !form.remark) {
                Toast.setDefaultOptions({
                    zIndex: 3000
                })
                Toast('请填写认定原因')
                return
            }
            let liabilityReason = createResponsiblePartyObj.resaon;
            createResponsiblePartyObj.responsibleParty && createResponsiblePartyObj.responsibleParty != form.responsibleParty && (liabilityReason = form.remark)
            vm.loading = true
            vm.$http({
                type: vm.editItem.id ? 'PUT' : "POST",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo`,
                except: true,
                data: {
                    operator: vm.name,
                    probeId: vm.form.probeId,
                    liabilityReason,
                    tagId: vm.form.tagId,
                    password: vm.$config.base.password,
                    responsibleParty: vm.form.responsibleParty
                }
            }).then(res => {
               vm.loading = false;
               if (res.code != 1) {
                   Toast(res.message)
                   return
               }
               vm.loadCheckList();
               vm.onCancel()
            }).catch(err => {
                vm.loading = false;
                console.log(err)
            });
        },
        inputChange(type) {
            const vm = this
            if (type == 'probeId' && vm.form.probeId.length == 8) {
                vm.$http({
                    type: "get",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.form.probeId}`,
                    except: true
                }).then(res => {
                    const { code, data } = res
                    if (code == 1 && data.status != 2) {
                        vm.singleItem = data;
                    } else {
                        vm.singleItem = {};
                    }
                });
            }

            if (type == 'search') {
                !vm.number && vm.loadCheckList();
                return
            }

            if (type == 'tag') {
                vm.$http({
                    type: "get",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/resp`,
                    except: true,
                    params: {
                        probeId: vm.form.probeId,
                        tagId: vm.form.tagId
                    }
                }).then(res => {
                    const { code, data, message } = res
                    if (code == 1) {
                        if (data && data.responsibleParty) {
                            vm.createResponsiblePartyObj = data;
                            vm.form.responsibleParty = data.responsibleParty
                            vm.isConfirmPass = data.responsibleParty
                        } else {
                            Toast.fail({
                                message: '探头ID不存在~'
                            })
                        }
                    } else {
                        Toast.fail({
                            message: message || '网络错误~'
                        })
                    }
                });
            }

            vm.isConfirmPass = (vm.form.probeId.length == 8 || vm.form.probeId.length == 9) && vm.form.tagId && vm.form.responsibleParty
        },

        editSingleItem() {
            const vm = this
            vm.editItem = Object.assign(vm.singleItem)
            vm.handleClick(vm.singleItem)
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-ryProbe-afterSale-list {
    box-sizing: border-box;
    width: 100%;
    // min-width: 1100px;
    overflow: auto;
    height: 100%;
    background: @blank;
    position: relative;
    padding: 100px 0 0px;
    font-size: 16px;
    color: @textColor9;
    padding-left: 160px;
    * {
        box-sizing: border-box;
    }
    .header {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        top: 0;
        line-height: 60px;
        padding: 0 20px 10px;
        padding-left: 180px;
        z-index: 5;
        background: @blank;
        .title {
            color: @textColor10;
            font-size: 24px;
            font-weight: bold;
        }
        .el-form-item__label {
            padding-right: 7px;
        }
        .header-right {
            font-size: 14px;
            .btn {
                height: 30px;
                line-height: 30px;
                width: 90px;
                text-align: center;
                border: 1px solid @textColor9;
                border-radius: 4px;
                display: inline-block;
                cursor: pointer;
                &.add-record {
                    background: @textColor3;
                    color: @blank;
                    border-color: @textColor3;
                    margin-left: 20px;
                }
            }
        }
    }
    .footer {
        height: 45px;
        padding: 5px 20px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 2px -3px 4px #bbb;
        padding-left: 180px;
        text-align: right;
        img {
            height: 35px;
        }
    }
    .content {
        padding: 120px 20px 80px;
        height: 100%;
        overflow-y: auto;
        &.max-height {
            padding-top: 55px;
            .filter-box {
                height: 110px;
            }
        }

        .el-table__body td {
            padding: 3px 0;
        }

        .hide-header {
            th:nth-child(3)  {
                display: none;
            }
            tr td:nth-child(3)  {
                display: none;
            }
        }

        .filter-box {
            padding: 10px 20px;
            position: fixed;
            left: 0px;
            right: 0px;
            z-index: 5;
            top: 45px;
            background: @blank;
            box-shadow: -2px 3px 4px #bbb;
            height: 110px;
            padding-left: 180px;

            .pos-filter-item {
                position: absolute;
                right: 120px;
                top: -53px;
                text-align: right;
            }

            .header-tab {
                line-height: 40px;
                padding: 0 15px;
                margin-bottom: 10px;
                span {
                    display: inline-block;
                    margin-right: 30px;
                    cursor: pointer;
                    position: relative;
                    z-index: 10;
                    &.active {
                        border-bottom: 3px solid #209A56;
                    }
                }
                .right-btn {
                    position: absolute;
                    right: 15px;
                    top: 0;
                    width: 100%;
                    text-align: right;
                    .warning-probe-btn {
                        padding: 0 15px;
                        border: 1px solid #515151;
                        border-radius: 5px;
                        line-height: 30px;
                        cursor: pointer;
                        color: #515151;
                        display: inline-block;
                        margin-left: 10px;
                        &.clear-warning-btn {
                            background: #209A56;
                            color: #fff;
                            border-color: #209A56;
                        }
                    }
                }
            }
        }
        .el-input-group__append {
            padding: 0;
            cursor: pointer;
            background: @textColor3;
            color: @blank;
            border: 1px solid @textColor3;
            span {
                padding: 20px;
            }
        }
        .pl-10 {
            .el-input__inner[type="text"] {
                padding-left: 10px;
            }
        }
        .el-pagination {
            padding-top: 30px;
            text-align: center;
        }
    }
    .el-dialog {
        margin: 0 auto;
        max-height: 80%;
        overflow: scroll;
        .warning-tip {
            text-decoration: underline;
            color: @textColor2;
            cursor: pointer;
        }

        .line-two {
            text-align: center;
            line-height: 40px;
        }

        .modal-tip {
            color: red;
            padding-top: 20px;
            text-align: center;
        }

        .el-tabs__nav {
            margin: 0 auto;
            width: 230px;
            float: none;
        }

        .select-model-item {
            width: 200px;
            line-height: 50px;
            margin: 20px auto;
            border: 1px solid #bbb;
            color: #000;
            text-align: center;
            font-size: 24px;
            cursor: pointer;
            &.active {
                color: #209A56;
                border-color: #209A56;
            }
        }

        .upload-demo {
            padding: 30px 20px;
            text-align: center;
            border: 1px dashed #666;
            border-radius: 10px;
            .el-icon-upload {
                font-size: 80px;
            }
            .el-upload-list__item .el-icon-close {
                display: block;
                right: 10%;
                &:before {
                    content: '删除'
                }
            }
        }

        .el-tab-checkStatus{
          //固定tab头
          position: sticky;
          top: 0;
          background: #fff;
          padding: 10px 0;
          z-index: 10;
          .el-badge{
            margin-top: 10px;
          }
        }

        .check-list-box {
            .check-status-empty {
                display: flex;
                border: 1px solid #666;
                border-radius: 5px;
                justify-content: space-between;
                padding: 10px;
                margin-bottom: 10px;
            }

            .check-status-item {
                border: 1px solid #666;
                border-radius: 5px;
                padding: 10px;
                margin-bottom: 10px;
                .item-header {
                    &:first-child {
                        font-size: 20px;
                    }
                }
                .pull-right {
                    float: right;
                    margin-left: 10px;
                }
                .item-imgs {
                    padding: 10px 0;
                    img {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        margin-right: 10px;
                    }
                }
                .item-info {
                    margin-bottom: 10px;
                }
                .item-desc, .item-info {
                    .title {
                        padding: 0 10px;
                        background: fade(@bgColor1, 20);
                        line-height: 40px;
                        position: relative;
                        button {
                            position: absolute;
                            right: 10px;
                            top: 7px;
                        }
                    }
                    span {
                        display: inline-block;
                        padding: 10px 30px 10px 0;
                    }
                    .el-button span {
                        padding: 0;
                    }

                    .empty-check {
                        padding: 10px 0;
                    }
                }
            }
        }

        .fail-res {
            .fail-tip {
                font-size: 14px;
                padding: 10px;
                span {
                    color: red;
                }
            }
            .fail-table {
                max-height: 200px;
                overflow-y: auto;
                border: 1px solid #eee;
                padding: 20px;
                & > div {
                    display: flex;
                    & > div {
                        line-height: 40px;
                        width: 50%;
                        text-align: center;
                    }
                }
            }
        }

        .success-res {
            text-align: center;
            .el-icon-success {
                color: green;
                font-size: 60px;
            }
            .success-title {
                font-size: 20px;
                margin: 20px 0;
            }
        }

        .info-content {
            padding: 20px 10px;
            border-radius: 9px;
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
            .info-content-item {
                padding-top: 20px;
                padding-right: 70px;
                position: relative;
                padding-left: 30px;
                i {
                    position: absolute;
                    left: 0px;
                    font-size: 20px;
                    color: #256FD9;
                    &.van-icon-clear {
                        color: #F52323;
                    }
                }

                span {
                    position: absolute;
                    right: 0;
                    color: #FB3D66;
                }

                &.pass {
                    color: #858585;
                    span {
                        color: #858585;
                    }
                }
            }
        }
    }

    .item-resaon {
        text-align: center;
        padding: 10px 0 20px;
        color: #E6A23C;
        font-size: 14px;
    }

    .van-dialog {
        z-index: 9999;
    }
}
.van-dialog {
    z-index: 99999;
}
</style>


